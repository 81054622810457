import React, {useEffect, useState} from "react"
import { Router } from "@reach/router"
import NavLink from "../components/NavLink/NavLink"

import { Container, Tab, Nav, Row, Col } from 'react-bootstrap'
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from "../components/PageHero/PageHero"
import PaperBackground from '../components/PaperBackground/PaperBackground'

import LocalSocialServices from '../components/Resources/TabContent/LocalSocialServices'
import AddictionSupportGroups from '../components/Resources/TabContent/AddictionSupportGroups'

import LocalNationalResources from '../components/Resources/LocalNationalResources'

import PageHeroBottom from "../components/PageHero/PageHeroBottom"
import AmoebaSlim from "../components/Amoebas/AmoebaSlim"
import GriefSupportGroupSessions from "../components/Resources/GriefSupportGroupSessions"
import RegionalMentalHealthProviders from "../components/Resources/TabContent/RegionalMentalHealthProviders"
import OtherResources from "../components/Resources/TabContent/OtherResources"
import ResourcesDefaultTab from "../components/Resources/TabContent/ResourcesDefaultTab"



const Resources = (props) => {
  const [key, setKey] = useState("family-therapy")

  useEffect(() => {
    setKey(props.location.pathname.id);

}, [props.location.pathname]);
    
    const currentTab = props.location.pathname;
 
 
  
  const bottomPhoto = props.data.wheatField.childImageSharp.fluid
  

  const document = props.data.allPrismicPage.edges[0].node.data

  const prismicHeroImage = props.data.allPrismicPage.edges[0].node.data.hero_image.localFile


  return (
  <Layout>
     <SEO title="Teton Valley Mental Health Coalition Resources" />
    <PageHero
      description={<><RichText render={document.hero_content.raw} /></>}
      image={prismicHeroImage}
      heading={document.hero_title.text}
      style={{color: '#434081'}}
      fillColor="#928D63"
      bgColor="#928D63"
    />
    <PaperBackground>
    <LocalNationalResources />
    <Container className="pb-4">

    <Tab.Container id="resources-tabs" defaultActiveKey="local-national" >
  <Row>
    <Col sm={3}>
      <Nav variant="pills"
        className="flex-column"
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        <Nav.Item>
          <NavLink to="/resources/local-and-national-resources" eventKey="local-national" id="local-services" role="tab" partiallyActive={true} className={`nav-link resources-nav ${currentTab === '/resources' ? 'active' : ''}`}>Local &amp; National Resources</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/resources/local-social-services-resources" eventKey="local" id="local-services" role="tab" partiallyActive={true} className={`nav-link resources-nav `}>Local Social Services &amp; Resources</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/resources/addiction-support-groups" eventKey="addiction" id="addiction-support-groups" role="tab" partiallyActive={true} className="nav-link resources-nav">Addiction Support Groups</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/resources/regional-providers-rehab-facilities" eventKey="regional" id="regional-providers" role="tab" partiallyActive={true} className="nav-link resources-nav">Regional Providers &amp; Rehab Facilities</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/resources/other-resources" eventKey="other" id="other-resources" role="tab" partiallyActive={true} className="nav-link resources-nav">Other Resources</NavLink>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={9}>
    <Tab.Content>
      {/* See gatsby-browswer.js for scollWillUpdate method what handles focus for these routes */}
    <Router basepath="/resources" primary={true}>
      <ResourcesDefaultTab path="/" />
      <ResourcesDefaultTab path="local-and-national-resources" />
      <LocalSocialServices path="local-social-services-resources" /> 
      <AddictionSupportGroups path="addiction-support-groups" />
      <RegionalMentalHealthProviders path="regional-providers-rehab-facilities" />
      <OtherResources path="other-resources" />
    
    </Router>
    </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
</Container>
<div>
  <AmoebaSlim
  fillColor="#434081"
/>
</div>

<GriefSupportGroupSessions />
<PageHeroBottom
      fillColor="#434081"
      bottomImage={bottomPhoto}
      heading="Teton Valley’s mental health matters and we’re here to support you."
    />
    </PaperBackground>
  </Layout>
  )
}

export const query = graphql`
{allPrismicPage(filter: {uid: {eq: "resources"}}) {
  edges {
    node {
      data {
        hero_title {
          text
        }
        hero_content {
          raw
        }
        hero_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
  allFile(filter: {sourceInstanceName: {eq: "pages"}, relativeDirectory: {eq: "resources"}, name: {eq: "index"}}) {
    edges {
      node {
        childMarkdownRemark {
          html
          frontmatter {
            title
            description
            heading
            photo {
              childImageSharp {
                fluid(maxWidth: 2500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  sittingAround: file(relativePath:{eq:"sitting-around@2x.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  sittingAroundMobile: file(relativePath:{eq:"sitting-around-mobile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  wheatField: file(relativePath:{eq:"wheatField.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Resources
