import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from 'gatsby-image'

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }
`;

const TabContent = styled.div`
  color: white;

  > p {
    font-weight: 300;
  }
  > ul {
    font-weight: 300;
    padding-left: 10px;
  }
`;

const Services = styled.div`
> p {
  font-size: 1.25rem;
  font-weight: 300;
}
`;

const OtherResources = () => {
    const data = useStaticQuery(graphql`
    {
      paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
    `)
    return (
        <>
        {/* <div className="mb-4" style={{
            position: 'relative'
        }}>
            <Img fluid={data.paperFamily.childImageSharp.fluid} alt="School-Based Counseling Program"  className="rounded"/>
            <HeaderImageWrapper>
            <div><p>2020-21 School-Based Counseling Program</p></div>
            </HeaderImageWrapper>
        </div> */}
        <TabContent>
          <h2>Other Resources</h2>
          <Services>
<p><a href="https://healthandwelfare.idaho.gov/services-programs/behavioral-health " target="_blank" rel="noopener noreferrer">Idaho Department of Health &amp; Welfare: Behavioral Health</a></p>
<p><a href="https://www.nimh.nih.gov/index.shtml" target="_blank" rel="noopener noreferrer">National Institute of Mental Health</a></p>
<p><a href="https://nami.org/Home" target="_blank" rel="noopener noreferrer">National Alliance on Mental Illness</a></p>

          </Services>
        </TabContent>
    </>
    )
}

export default OtherResources