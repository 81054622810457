import React from 'react'
import { Link } from 'gatsby'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons"

const LocalNationalResources = () => {
    return (
        <>
        <Container style={{
            paddingTop: '3rem',
            paddingBottom: '3rem'
        }}>
            <Row className="text-center">
                <Col>
                <Link to='/counselors-programs' className="text-center"><Button className="tealButton btn-lg">View Teton Valley Mental Health Coalition Counselors <FontAwesomeIcon
                        icon={faAngleRight}
                        aria-hidden="true"
            
                    /></Button></Link>    
                </Col>
            </Row>
            
        <h2 className="sectionTitle">
            Local and National Resources
          </h2>
          <h3 className="subSectionTitleLight">
          Are you searching for local, regional or national mental health resources?<br />Help is only a phone call away. 
          </h3>
        </Container>
        </>
    )
}

export default LocalNationalResources