import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'

const GriefSupportGroupSessions = () => {
    return (
        <>
        <div style={{
            backgroundColor: '#434081',
            marginTop: '-2px'
        }}>
            <Container className="pb-4">
                <Row>
                    <Col>
                        <h2 className="sectionTitle" style={{color: 'white'}}>Grief Support Group Sessions</h2>
                        <h3 className="subSectionTitleLight"  style={{color: 'white'}}>A series of free, confidential, supportive group sessions for parents who have experienced the loss of a child.</h3>
                        <h4 style={{color: 'white', textAlign: 'center', fontWeight: 300}}>Facilitated by Licensed Therapist Christina Riely, LCSW.<br />Funding provided by Teton Valley Mental Health Coalition.</h4>
                        <div className="text-center mt-4">
                            <Link to="/counselors-programs/grief-support">
                                    <Button className="tealButton btn-lg">Learn More</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default GriefSupportGroupSessions